import React, { Suspense, Fragment } from 'react';
const AdminRoutes = React.lazy(() => import('../components/Admin/AdminRoutes'));

export default function () {
  return (
    <Fragment>
      {/*! DO NOT CHANGE ADMIN ROUTES.. ALL ROUTES OF ADMINS ARE MANAGED BY BELOW COMPONENT!*/}
      <Suspense fallback={<div>Loading...</div>}>
        <AdminRoutes />
      </Suspense>
    </Fragment>
  );
}
