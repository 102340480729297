import { LOCALENV } from "../../components/common/config/index";

let doctorProfileMapUrl = {
  dev: "https://dpfd.c2cassets.com/",
  dev1: "https://dpfd.c2cassets.com/",
  dev2: "https://dpfd.c2cassets.com/",
  qa: "https://dpfq.c2cassets.com/",
  uat: "https://dpfu.c2cassets.com/",
  prod: "https://dpfp.connect2clinic.com/",
  stage: "https://dpfs.c2cassets.com",
  maint: "https://dpfm.c2cassets.com",
};
let doctorFilesMapUrl = {
  dev: "https://dfd.c2cassets.com/",
  dev1: "https://dfd.c2cassets.com/",
  dev2: "https://dfd.c2cassets.com/",
  qa: "https://dfq.c2cassets.com/",
  uat: "https://dfu.c2cassets.com/",
  stage: "https://dfs.c2cassets.com/",
  maint: "https://dfm.c2cassets.com/",
  prod: "https://dfp.connect2clinic.com/",
};
let patientFilesMapUrl = {
  dev: "https://pfd.c2cassets.com/",
  dev1: "https://pfd.c2cassets.com/",
  dev2: "https://pfd.c2cassets.com/",
  qa: "https://pfq.c2cassets.com/",
  uat: "https://pfu.c2cassets.com/",
  stage: "https://pfs.c2cassets.com/",
  maint: "https://pfm.c2cassets.com/",
  prod: "https://pfp.connect2clinic.com/",
};
let siteURLMapUrl = {
  dev: "https://dev.c2cassets.com/",
  dev1: "https://dev1.c2cassets.com/",
  dev2: "https://dev2.c2cassets.com/",
  uat: "https://uat.c2cassets.com/",
  qa: "https://qa.c2cassets.com/",
  prod: "https://www.connect2clinic.com/",
  stage: "https://stage.c2cassets.com",
  maint: "https://maint.c2cassets.com/",
};

let doctorknowledgecenterMapUrl = {
  dev: "https://dev-doctor-content.c2cassets.com/",
  dev1: "https://dev-doctor-content.c2cassets.com/",
  dev2: "https://dev-doctor-content.c2cassets.com/",
  uat: "https://dev-doctor-content.c2cassets.com/",
  maint: "https://dev-doctor-content.c2cassets.com/",
  prod: "https://doctor-content.connect2clinic.com/",
  qa: "https://dev-doctor-content.c2cassets.com/",
};

let patientknwledgecenterMapurl = {
  dev: "https://dev-patient-content.c2cassets.com/",
  dev1: "https://dev-patient-content.c2cassets.com/",
  dev2: "https://dev-patient-content.c2cassets.com/",
  uat: "https://dev-patient-content.c2cassets.com/",
  prod: "https://patient-content.connect2clinic.com/",
  qa: "https://dev-patient-content.c2cassets.com/",
};

let cookiesDomainMapUrl = {
  dev: ".c2cassets.com",
  dev1: ".c2cassets.com",
  dev2: ".c2cassets.com",
  uat: ".c2cassets.com",
  prod: ".connect2clinic.com",
  qa: ".c2cassets.com",
  maint: ".c2cassets.com",
  stage: ".c2cassets.com",
};

let pemMapUrl = {
  dev: "https://pem-dev.c2cassets.com",
  uat: "https://pem-uat.c2cassets.com",
  prod: "https://pem.connect2clinic.com",
  qa: "https://pem-qa.c2cassets.com",
  maint: "https://pem-maint.c2cassets.com",
  stage: "https://pem-stage.c2cassets.com",
};

let QUEUE_DASHBOARD_URL = {
  dev: "https://qdev.c2cassets.com",
  qa: "https://qqa.c2cassets.com",
  uat: "https://quat.c2cassets.com",
  maint: "https://qmaint.c2cassets.com",
  prod: "https://queue.connect2clinic.com",
};

let newMedUpdateMapsURL = {
  dev: "https://mu-dev.c2cassets.com/",
  qa: "https://mu-qa.c2cassets.com/",
  uat: "https://mu-uat.c2cassets.com/",
  prod: "https://mu.connect2clinic.com/",
  maint: "https://mu-maint.c2cassets.com/",
  stage: "https://mu-stage.c2cassets.com/",
};

let widgetCodeMapsURL = {
  dev: "https://widget-dev.c2cassets.com",
  qa: "https://widget-qa.c2cassets.com",
  uat: "https://widget-uat.c2cassets.com",
  prod: "https://widget.connect2clinic.com",
  maint: "https://widget-maint.c2cassets.com",
};

let pharmacyMapUrl = {
  dev: "https://pharmapi-dev.c2cassets.com",
  qa: "https://pharmapi-qa.c2cassets.com",
  uat: "https://pharmapi-uat.c2cassets.com",
  prod: "https://pharmapi.connect2clinic.com",
  maint: "https://pharmapi-maint.c2cassets.com",
};

const api =
  window.location.hostname === "localhost"
    ? LOCALENV
    : process.env.REACT_APP_ENV;

const doctorBaseUrl = doctorknowledgecenterMapUrl[api];
const patientBaseUrl = patientknwledgecenterMapurl[api];
const doctorProfileFiles = doctorProfileMapUrl[api];
const pemUrl = pemMapUrl[api];
const pharmacyUrl = pharmacyMapUrl[api];
const newMedUpdatesURL = newMedUpdateMapsURL[api];

const doctorFiles = doctorFilesMapUrl[api];

const patientFiles = patientFilesMapUrl[api];

const siteURL = siteURLMapUrl[api];

const widgetBaseURL = widgetCodeMapsURL[api];

const cookiesDomainUrl =
  window.location.hostname === "localhost"
    ? "localhost"
    : cookiesDomainMapUrl[process.env.REACT_APP_ENV];

export {
  doctorProfileFiles,
  doctorFiles,
  patientFiles,
  siteURL,
  doctorBaseUrl,
  patientBaseUrl,
  pemUrl,
  pharmacyUrl,
  cookiesDomainUrl,
  newMedUpdatesURL,
  QUEUE_DASHBOARD_URL,
  widgetBaseURL,
};
