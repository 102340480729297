import Dexie from "dexie";

const dexie_db = new Dexie("C2C");
dexie_db.version(1).stores({
  appointment: "clinic_id",
});

dexie_db.open().catch((err) => {
  console.log(err.stack || err);
});

export const deleteData = async (clinic_id, date, id) => {
  console.log("clinic_id", clinic_id, date, id);
  dexie_db.appointment
    .where("clinic_id")
    .equals(clinic_id)
    .modify((appt) => {
      console.log(appt);
      delete appt[date].data[id];
      // delete appt.data[id];
    });
  let updatedData = await dexie_db.appointment.toArray();
  return updatedData;
};

export const deleteDB = async () => {
  dexie_db
    .delete()
    .then(() => {
      console.log("Database successfully deleted");
    })
    .catch((err) => {
      console.error("Could not delete database");
    })
    .finally(() => {
      dexie_db.open();
      // Do what should be done next...
    });
};

export const updateData = async (clinic_id, date, data) => {
  let finalData = await dexie_db.appointment
    .where("clinic_id")
    .equals(clinic_id)
    .modify((app) => {
      app[date].data[data.id].patient_name = data.patient_name;
    });

  return finalData;
};
export default dexie_db;
