export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";
export const DrugsSize = 30;

export const USER_TYPE = {
  DOCTOR: "doctor",
  STAFF: "staff",
  PATIENT: "patient",
  SUPPORT: "support",
};

export const cellViewType = {
  Shared: "shared",
  Content: "content",
  upload: "upload",
};

export const fileType = {
  pdf: "application/pdf",
  mp4: "video/mp4",
  mp3: "audio/mpeg",
  png: "image/png",
  jpg: "image/jpeg",
};

export const PatientSideMenu = [
  {
    name: "Home",
    value: 1,
    method: "navComponent",
    className: "dashboard",
  },
  {
    name: "Appointment History",
    value: 2,
    method: "navComponent",
    className: "previous-patients",
  },
  {
    name: "Book Appointment",
    value: 3,
    method: "navComponent",
    className: "previous-patients",
  },
  {
    name: "Patient Education",
    value: 4,
    method: "navComponent",
    className: "",
  },
  {
    name: "Help & Support",
    value: 5,
    method: "navComponent",
    link: true,
    to: "/help",
  },
  {
    name: "Logout",
    value: 6,
    method: "logOut",
  },
];

export const DoctorSideMenu = [
  {
    name: "Home",
    value: 1,
    method: "navComponent",
    className: "dashboard",
  },
  {
    name: "My Appointments",
    value: 2,
    method: "navComponent",
    className: "previous-patients",
  },

  {
    name: "My Patients",
    value: 3,
    method: "navComponent",
    className: "",
  },
  {
    name: "Patient Education",
    value: 4,
    method: "navComponent",
    className: "",
    // isnew:true
  },
  {
    name: "C2C Med Updates",
    value: 12,
    method: "navComponent",
    className: "",
    isnew: true,
  },
  {
    name: "Patient Coupons",
    value: 10,
    method: "navComponent",
    className: "",
  },
  {
    name: "Messages",
    value: 5,
    method: "navComponent",
    className: "messages-ride",
  },
  {
    name: "My Transactions",
    value: 6,
    method: "navComponent",
    className: "",
  },
  {
    name: "My Schedule & Fees",
    value: 7,
    method: "navComponent",
    className: "my-schedule-fees",
  },
  {
    name: "Settings",
    value: 8,
    method: "navComponent",
    className: "",
  },
  {
    name: "Help & Support",
    value: 9,
    method: "navComponent",
    link: true,
    to: "/help",
  },
  {
    name: "Logout",
    value: 11,
    method: "logOut",
  },
];


export const MAX_FILE_SIZE_MED_UPDATE_JOURNAL = 20000000; //20MB
export const ACCEPTED_FILE_FORMATS_MED_UPDATE_JOURNAL = [
	{ type: 'image/jpg'},
	{ type: 'image/jpeg' },
	{ type: 'image/png' },
];
export const REGEX_FORMATS_MED_UPDATE_JOURNAL = /(\.jpg|\.jpeg|\.png)$/i;
export const JOURNAL_DOC_RESOLUTION_MED_UPDATE = {height:"200",width:"200"}