import { Component, default as React } from 'react';
import { Row } from 'react-bootstrap';
import c2cAppImg from '../../assets/c2cAppImgNew.jpg';
import '../../components/styles/Homepage.scss';
import '../../globalStyles/main.css';
import './styles/Tablet.scss';
export class Tablet extends Component {
	render() {
		return (
			<div>
				<div className="the-color-of-tablet">
					<Row className="tablet-outer">
						<section className="app-screen">
							{/* <div className="container">
            <div className="row"> */}
							{/* <div className="col-md-6">
                <div className="app-content">
                  <h3>
                    Coming Soon... Mobile App for an enhanced mobile experience
                  </h3>
                </div>
              </div> */}
							{/* <div className="col-md-6">
                <img
                  src={process.env.PUBLIC_URL + "images/app-screen.png"}
                  alt=""
                />
              </div> */}
							<img
								src={process.env.PUBLIC_URL + 'images/homepage-promotional-banner'}
								alt=""
								className="background-banner-web"
							/>
							<img src={c2cAppImg} alt="" className="background-banner-mob" />
							<div className="store-link">
								<img
									src={process.env.PUBLIC_URL + 'images/downloadlink-qrcode'}
									alt=""
									className="qrcode-link"
								/>
								<span>Download now</span>
								<a
									href={'https://play.google.com/store/apps/details?id=com.connect2clinic.app'}
									target="_blank"
								>
									<img
										src={process.env.PUBLIC_URL + 'images/downloadlink-playstore'}
										alt=""
										className="playstore-link"
									/>
								</a>
								<a href={'https://apps.apple.com/in/app/connect2clinic/id1530572274'} target="_blank">
									<img
										src={process.env.PUBLIC_URL + 'images/downloadlink-iphone'}
										alt=""
										className="applestore-link"
									/>
								</a>
							</div>
							{/* </div>
          </div> */}
						</section>
						{/* <img src={AppImage} alt="#" /> */}

						{/* <Col className="col-12 col-md-7 the-text-tablet-position">
            <div className="text-table-color">
              <div className="accessible-text-pos text-style-accessible">
                Accessible From Everywhere
              </div>
              <div className="text-style-accessible">
                Your Desktop, Tablet & Smartphone
              </div>
            </div>
            <div className="images-tab-position-mob">
              <div>
                <img src={Applemob} alt="#" />
              </div>
              <div>
                <img src={PlayStoremob} alt="#" />
              </div>
            </div>

            <div className="images-tab-position">
              <div>
                <img src={Apple} alt="#" />
              </div>
              <div>
                <img src={PlayStore} alt="#" />
              </div>
            </div>
          </Col>
          <Col className="col-12 col-md-5 tablet-position-new">
          <div>
            <img
              src={require("../../assets/Tablet/Tablettablet.png")}
              alt="#"
            />
          </div>
          </Col> */}
					</Row>
				</div>

				<div className="the-color-of-tablet-mob">
					<Row className="tablet-outer">
						<img src={c2cAppImg} alt="#" />
					</Row>
				</div>
			</div>
		);
	}
}

export default Tablet;
