import React, { useState, useEffect } from "react";
import beforeLoginApiCall from "../../../Util/API Calls/beforeLogin";
import { doctorProfileFiles } from "../../../Util/API Calls/APIList";
import searchIcon from "../../../assets/forhomepage/search_icon.svg";
import closeIcon from "../../../assets/forhomepage/close_icon.svg";
import person from "../../../assets/forhomepage/person.png";
import "./DoctorInputSearch.scss";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

function DoctorInputSearch(props) {
  const [doctorList, setDoctorList] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const debouncedSearchTerm = useDebounce(inputValue, 500);
  const url = "doctor/";

  useEffect(
    () => {
      if (debouncedSearchTerm.length > 2) {
        if (debouncedSearchTerm.includes(url)) {
          //input.startsWith('http') && input.includes("/doctor/")
          let a = debouncedSearchTerm.split("/");
          let searchParams = a[a.length - 1];
          if (searchParams.length > 2) {
            beforeLoginApiCall
              .post("/public/doctor-search-by-name", {
                search: searchParams,
                prefix_url: debouncedSearchTerm.split("/")[2],
              })
              .then((res) => {
                if (res.data.statusCode == 200) {
                  setDoctorList(res.data.list);
                }
              });
          } else {
            setDoctorList([]);
          }
        } else {
          beforeLoginApiCall
            .post("/public/doctor-search-by-name", {
              search: debouncedSearchTerm,
            })
            .then((res) => {
              if (res.data.statusCode == 200) {
                setDoctorList(res.data.list);
              }
            });
        }
      } else {
        setDoctorList([]);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
  const handleEnter = (event) => {
    if (event.which === 13 && doctorList.length === 1) {
      window.open(
        `${window.location.origin}/doctor/${
          doctorList && doctorList[0].short_url_slug
        }`,
        "_self"
      );
    }
  };

  const handleListClick = (doctor) => {
    window.open(
      `${window.location.origin}/doctor/${doctor.short_url_slug}`,
      "_self"
    );
  };

  const closeData = () => {
    setDoctorList(null);
    setInputValue("");
    props.closeData();
  };
  const handleSearch = (event) => {
    let url = window.location.href + "doctor/";
    let input = event.target.value;
    console.log(input);
    setInputValue(input);
    if (input.length > 2) {
      if (input.includes(url)) {
        //input.startsWith('http') && input.includes("/doctor/")
        let a = input.split("/");
        let searchParams = a[a.length - 1];
        if (searchParams.length > 2) {
          beforeLoginApiCall
            .post("/public/doctor-search-by-name", {
              search: searchParams,
            })
            .then((res) => {
              if (res.data.statusCode == 200) {
                setDoctorList(res.data.list);
              }
            });
        } else {
          setDoctorList(null);
        }
      } else {
        beforeLoginApiCall
          .post("/public/doctor-search-by-name", {
            search: input,
          })
          .then((res) => {
            if (res.data.statusCode == 200) {
              setDoctorList(res.data.list);
            }
          });
      }
    } else {
      //else if(!input)
      setDoctorList(null);
    }
  };

  return (
    <div
      className={`search-wrapper ${
        doctorList && inputValue.length > 2 ? "isActive" : ""
      } `}
    >
      <div className="input-holder">
        <input
          type="text"
          className="search-input"
          onKeyPress={(e) => handleEnter(e)}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Search by doctor name (or) doctor link"
          value={inputValue}
        />{" "}
        {/**onChange={handleSearch} */}
        <div className="search-icon">
          <img alt="" src={searchIcon} />
        </div>
        <div className="close_serch" onClick={closeData}>
          <img alt="" src={closeIcon} />
        </div>
      </div>
      <div className="searched_value">
        {doctorList && doctorList.length > 0 ? (
          <ul>
            {doctorList &&
              doctorList.map((item) => (
                <li onClick={() => handleListClick(item)}>
                  <div className="searched_item">
                    <div className="searched_item_img">
                      {item.profile_picture_url !== "" ? (
                        <img
                          alt=""
                          src={doctorProfileFiles + item.profile_picture_url}
                        />
                      ) : (
                        <div className="p-2 text-center searched_item_no_img">
                          {item.first_name.charAt(0)}
                          {item.last_name ? item.last_name.charAt(0) : ""}
                        </div>
                      )}
                    </div>
                    <div className="searched_item_text">
                      <h5>
                        {item.prefix_name.includes(".")
                          ? item.prefix_name
                          : `${item.prefix_name}.`}{" "}
                        {item.first_name} {item.middle_name} {item.last_name}
                      </h5>
                      <p>
                        {item.display_name}-{item.address}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          <div className="no_data_found">No data found</div>
        )}
      </div>
    </div>
  );
}

export default DoctorInputSearch;
