import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import LoginDrawer from "./Faq/LoginDrawer";
import MyAccountAndLoginButton from "../components/HomepageNew/MyAccountAndLoginButton";
import "./styles/Faq.scss";
import "./PreviousPatients/previousPatientsStyles.scss";
import "./PreviousPatients/tooltip.scss";

class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      type: this.props.type || "",
      redirect: this.props.redirect == undefined ? false : this.props.redirect,
      logout: false,
    };
  }
  logOut() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("user_type");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("full_name");
    localStorage.removeItem("meeting_id");
    window.location.reload();
  }

  componentDidMount() {
    if (
      localStorage.getItem("id_token") &&
      localStorage.getItem("user_type") === "patient"
    ) {
      this.setState({ logout: true });
    }
  }

  handleRedirectBackToDoctorProfile() {
    if (this.props.redirect === true) {
      const pathname = this.props.history.location.pathname;
      localStorage.setItem(
        "redirect_patient",
        JSON.stringify({ redirect_url: pathname })
      );
    }
  }
  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const fromApp = urlParams.get("fromApp");
    return (
      <>
        {fromApp == "true" ? null : (
          <header
            style={{
              boxShadow: "0px 3px 6px #00000029",
              position: "relative",
              zIndex: "10",
            }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="App">
                  <div className="c2clogo">
                    <img src={require("../assets/c2c_logo.png")} alt="#" />
                  </div>

                  <div className="header-content-container">
                    <div className="header-content">
                      <div className="align-header">
                        <img src={require("../assets/call-24px.png")} alt="#" />
                        <div>
                          {" "}
                          <a
                            href={"tel:" + this.props.mobileNumber}
                            className="a"
                          >
                            {this.props.mobileNumber}
                          </a>
                        </div>
                      </div>
                      <div className="align-header2">
                        <img src={require("../assets/mail-icon.png")} alt="#" />
                        <a href={"mailto:" + this.props.emailId} className="a">
                          {this.props.emailId}
                        </a>
                      </div>
                      <div className="wrapper">
                        {console.log("logout =>", this.state.logout)}
                        <MyAccountAndLoginButton isComingFromHeader={true} />
                      </div>
                    </div>
                  </div>

                  <div className="drawer-nav">
                    <LoginDrawer />
                  </div>
                </div>
              </div>
            </div>
          </header>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileNumber: state.global.mobileNumber,
  emailId: state.global.emailId,
});

export default compose(withRouter, connect(mapStateToProps))(Header);

