import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Call from "../../assets/forhomepage/call.svg";
import Mail from "../../assets/forhomepage/mail.svg";
import "./styles/Homepagec2c.scss";
import "./styles/Menu.scss";
import C2cimage from "../../assets/forhomepage/c2cimg.svg";
import Arrowdown from "../../assets/forhomepage/arrowdown-newhomepage.svg";
import PersonHome from "../../assets/forhomepage/person-newHomepage.svg";
import searchIconWhite from "../../assets/forhomepage/search_icon_white.svg";
// import Participant from "./Participant";
// import AboutUs from "./AboutUs";
// import MaskedDoctor from "./MaskedDoctor";
// import ForPatient from "./ForPatient";
import Tablet from "./Tablet";
// import CustomerReview from "./CustomerReview";
// import AreYouDoctor from "./AreYouDoctor";
// import BottomFooter from "./BottomFooter";
//import VerticalParticipant from "./VericalPaticipant";
// import VerticalAbout from "./VerticalAboutUs";
//import { SidebarData } from "./SidebarData";
import MenuNewHomepage from "../../assets/forhomepage/menu-new-homepage.svg";
import ChatIcon from "../../assets/forhomepage/chat-icon.png";
import { Redirect } from "react-router-dom";
import appleIcon from "../../assets/apple_icon_new.png";
import playstoreIcon from "../../assets/playstore_icon_new.png";
import Close_icon from "../../assets/cross.png";
import BackToTop from "../../assets/BackToTop.png";
import Chrome from "../../assets/chrome.png";
import Safari from "../../assets/safari.png";
import DoctorInputSearch from "../common/DoctorInputSearch/DoctorInputSearch";
// import MyAccountAndLoginButton from "./MyAccountAndLoginButton.js";
import { useLastLocation } from "react-router-last-location";

function Homepagec2c() {
  const [sidebar, setSidebar] = useState(false);
  const [serchContainer, setSerchContainer] = useState(false);
  const [userBrowser, setUserBrowser] = useState(false);
  const lastLocation = useLastLocation();

  console.log("lastLocation", lastLocation);
  let staff_clinic_association = localStorage.getItem(
    "staff_clinic_association"
  );
  let doctor_clinic_association = localStorage.getItem(
    "doctor_clinic_association"
  );
  const showSidebar = () => setSidebar(!sidebar);
  const openSerchContainer = () => {
    setSerchContainer(!serchContainer);
  };
  const removeData = () => {
    setSerchContainer(false);
  };
  const iOSDeviceDetect = () => {
    console.log("called");
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };
  console.log("iOSDeviceDetect", iOSDeviceDetect());

  useEffect(() => {
    console.log("Homepagec2c useeffect");
    console.log(
      "lastLocation",
      lastLocation,
      window.localStorage.getItem("user_type")
    );
    if (lastLocation === null) {
      console.log("I am null");
      let userType = window.localStorage.getItem("user_type");
      console.log("userType", userType);
      if (userType) {
        if (
          userType === "patient" ||
          userType === "doctor" ||
          userType === "staff"
        ) {
          if (
            staff_clinic_association === "true" ||
            doctor_clinic_association === "true"
          ) {
            userType = "clinic";
            window.open(
              `${window.location.origin}/${userType}/account/dashboard`,
              "_self"
            );
          } else {
            if (userType === "staff") {
              userType = "doctor";
              window.open(
                `${window.location.origin}/${userType}/account/dashboard`,
                "_self"
              );
            } else {
              window.open(
                `${window.location.origin}/${userType}/account/dashboard`,
                "_self"
              );
            }
          }
        }
      }
    }
    let is_Chrome = navigator.userAgent.indexOf(" Chrome/") > 0;
    let is_Edge = navigator.userAgent.indexOf(" Edg/") > 0;
    let is_Mozilla = navigator.userAgent.indexOf("Mozilla") != -1;
    let is_Safari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );
    var is_Opera = !!window.opera || navigator.userAgent.indexOf("OPR/") >= 0;

    if (iOSDeviceDetect() && is_Mozilla) {
      setUserBrowser(false);
      return;
    }
    if (is_Edge || is_Opera) {
      setUserBrowser(true);
    } else {
      if (!(is_Chrome || is_Safari)) {
        setUserBrowser(true);
      }
    }
  }, []);

  return (
    <div className>
      <div className="posit-paper">{/* <Participant /> */}</div>
      <div className="vertical-posit-participant">
        {/* <VerticalParticipant /> */}
      </div>
      <Row className="top-covid-heading">
        <div className="col-xl-6 content-1 col-sm-12">{`Worried about Covid - 19? Don’t Panic. Connect with your doctor now.`}</div>
        <div className="content-1 mob_message">{`Worried about Covid - 19? Don’t ...`}</div>

        <div className="col-xl-4 content-mob">
          <div>Download App</div>
          <div className="image-style">
            {" "}
            <a
              href={"https://apps.apple.com/in/app/connect2clinic/id1530572274"}
              target="_blank"
            >
              <img src={appleIcon} alt="#" />
            </a>
          </div>
          <div className="image-style">
            {" "}
            <a
              href={
                "https://play.google.com/store/apps/details?id=com.connect2clinic.app"
              }
              target="_blank"
            >
              <img src={playstoreIcon} alt="#" />
            </a>
          </div>
        </div>
        <div className="col-xl-4 content-2">
          <div className="image-style">
            {" "}
            <a
              href={"https://apps.apple.com/in/app/connect2clinic/id1530572274"}
              target="_blank"
            >
              <img src={appleIcon} alt="#" />
            </a>
          </div>
          <div className="image-style">
            {" "}
            <a
              href={
                "https://play.google.com/store/apps/details?id=com.connect2clinic.app"
              }
              target="_blank"
            >
              <img src={playstoreIcon} alt="#" />
            </a>
          </div>
          <div>Download App</div>
          <div className="image-style">
            {" "}
            <img src={Call} alt="#" />
          </div>
          <div>
            <a href="tel:1800 267 9899">1800 267 9899</a>
          </div>
        </div>
        <Col className="col-xl-2 content-3">
          <div className="image-style">
            {" "}
            <img src={Mail} alt="#" className="header-eamil-icon" />
          </div>
          <div>
            <a href="mailto:hello@connect2clinic.com">
              hello@connect2clinic.com
            </a>
          </div>
        </Col>
      </Row>

      <div className="bg-image-home" id="top">
        <div className="themobpadding" style={{ marginTop: "20px" }}>
          <Row className="home-header home-header-center">
            <Col className="menuNewHomepage col-lg-1">
              <img src={MenuNewHomepage} alt="#" onClick={showSidebar} />
            </Col>

            <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
              <ul className="nav-menu-items" onClick={showSidebar}>
                <li className="navbar-toggle">
                  <div>
                    <a to="#" className="menu-bars">
                      <img src={Close_icon} alt="#" />
                    </a>
                  </div>
                </li>
                {/* {SidebarData.map((item, index) => {
                  return (
                    <>
                      <li key={index}>
                        <a href={item.path}>
                          <div className={item.cName}>
                            <span>{item.title}</span>
                          </div>
                        </a>
                      </li>
                    </>
                  );
                })} */}
              </ul>
              <div className="customer_support">
                <h6
                  style={{
                    fontSize: "14px",
                    color: "#F969A4",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  CUSTOMER SUPPORT
                </h6>
                <div className="image-style">
                  {" "}
                  <img src={Call} alt="#" />
                  <div>
                    <a href="tel:1800 267 9899">1800 267 9899</a>
                  </div>
                </div>
                <div className="image-style">
                  {" "}
                  <img src={Mail} alt="#" className="header-eamil-icon" />
                  <div>
                    <a href="mailto:hello@connect2clinic.com">
                      hello@connect2clinic.com
                    </a>
                  </div>
                </div>
              </div>
            </nav>

            <Col className="col-lg-3 tex-content-homepage-image">
              {" "}
              <img src={C2cimage} alt="#" />
            </Col>

            {
              <Col
                className="col-lg-1 tex-content-homepage-about-us"
                style={{ padding: "0px" }}
              >
                {" "}
                <a href="#about_us">About Us</a>
              </Col>
            }
            <Col
              className="col-lg-1 tex-content-homepage"
              style={{ padding: "0px" }}
            >
              {" "}
              <a href="#masked_doctor">For Doctors</a>
            </Col>
            <Col
              className="col-lg-1 tex-content-homepage"
              style={{ padding: "0px" }}
            >
              {" "}
              <a href="#for_patient"> For Patients</a>
            </Col>
            <Col
              className="col-lg-1 tex-content-homepage"
              style={{ padding: "0px" }}
            >
              <a href="/help">{`FAQ'S`}</a>
            </Col>
            {/* <Col className="col-lg-2 button-position pad-button-top">
              <a href="/doctor/login">
                <div className="button-faq">Doctor Login</div>
              </a>
            </Col>
            <Col className="col-lg-2 pad-button-top">
              <a href="/patient/login">
                <div className="button-homepage">Patient Login</div>
              </a>
            </Col> */}

            <Col
              className={`col-lg-4 search_container_for_mobile ${
                serchContainer ? "openSerchBox" : ""
              }`}
            >
              <DoctorInputSearch closeData={removeData} />
            </Col>

            <Col className="search_icon_for_mobile">
              <img
                alt=""
                src={searchIconWhite}
                onClick={() => openSerchContainer()}
              />
            </Col>

            <Col className="col-lg-1 mob-loginsection">
              {/* <div className="wrapper-login"> */}
              {/* <img src={PersonHome} alt="#" /> */}
              {/* <div className="right-menu"> */}
              {/* <MyAccountAndLoginButton /> */}
              {/* </div> */}
              {/* <div className="down_icon"> */}
              {/* <img src={Arrowdown} alt="#" /> */}
              {/* </div> */}
              {/* </div> */}
              {/* <div className="user_icon">
                <img src={PersonHome} alt="#" />
              </div>
              <div className="login-mob" data-toggle="dropdown">
                Login
              </div>
              <div className="down_icon">
                <img src={Arrowdown} alt="#" />
              </div>
              <ul className="dropdown-menu">
                <li>
                   <a 
                    href={` ${localStorage.getItem("stay-login") === "true" && localStorage.getItem("user_type") === "doctor" &&  localStorage.getItem("id_token") !== "" ? "/doctor/account/" : "/doctor/login"}`} >
                    Doctor Login</a>
                </li>
                <li>
                  <a 
                    href={` ${localStorage.getItem("stay-login") === "true" && localStorage.getItem("user_type") === "patient" &&  localStorage.getItem("id_token") !== "" ? "/patient/account/" : "/patient/login"}`} >
                    Patient Login</a>
                </li>
              </ul> */}
            </Col>
          </Row>
        </div>
        <div>
          <hr className="hr-color-homepage" />
        </div>
        <Row>
          <Col className="col-12 py-8 text-of-nowconsult col-xs-7 col-sm-12 col-lg-7">
            {`Now Consult`}
            <br /> {`Anywhere, Anytime`}
          </Col>
          <Col className="col-sm-12  tap-text col-lg-5">{`Tap into the Best Virtual Healthcare Network`}</Col>
        </Row>

        <Row className="doctor-patient-button-margin">
          <Col className="col-5 bigger-button-in-homepage col-sm-3 col-md-2">
            <a
              href={` ${
                localStorage.getItem("stay-login") === "true" &&
                (localStorage.getItem("user_type") === "doctor" ||
                  localStorage.getItem("user_type") === "staff") &&
                localStorage.getItem("id_token") !== ""
                  ? lastLocation?.pathname?.includes("clinic")
                    ? "/clinic/account/dashboard"
                    : "/doctor/account/dashboard"
                  : (staff_clinic_association === "true" ||
                      doctor_clinic_association === "true") &&
                    localStorage.getItem("id_token") !== ""
                  ? "clinic/account/dashboard"
                  : (staff_clinic_association === "false" ||
                      doctor_clinic_association === "false") &&
                    localStorage.getItem("id_token") !== ""
                  ? "doctor/account/dashboard"
                  : "/doctor/login"
              }`}
            >
              <div className="doctor-bb">Doctor Login</div>
            </a>{" "}
          </Col>
          <Col className="col-5  bigger-button-patient col-sm-3 col-md-2">
            <a
              href={` ${
                localStorage.getItem("stay-login") === "true" &&
                localStorage.getItem("user_type") === "patient" &&
                localStorage.getItem("id_token") !== ""
                  ? "/patient/account/dashboard"
                  : "/patient/login"
              }`}
            >
              <div className="patient-bb"> Patient Login</div>
            </a>
          </Col>
        </Row>
        {/* <div className="chat-button">
          <img src={ChatIcon} alt="#" />
        </div> */}
        <div className="chat-button">
          <a href="#top">
            <img src={BackToTop} alt="#" />
          </a>
        </div>
      </div>

      {
        <Row className="about-us-color-code" id="about_us">
          <Col className="hor">{/* <AboutUs /> */}</Col>
          <Col className="ver">{/* <VerticalAbout /> */}</Col>
        </Row>
      }

      <Row>
        <Col>{/* <MaskedDoctor /> */}</Col>
      </Row>
      <Row>
        <Col>{/* <ForPatient /> */}</Col>
      </Row>
      <Row>
        <Col>
          <Tablet />
        </Col>
      </Row>
      <Row>
        {/* <Col>
          <CustomerReview />
        </Col> */}
        <Col className="col-12">{/* <AreYouDoctor /> */}</Col>
        <Col className="co-12">{/* <BottomFooter /> */}</Col>
      </Row>
      {userBrowser === true ? (
        <div className="unsupported-browser-alert">
          <div className="round">
            <img
              className="closebtn"
              alt=""
              style={{ color: "black" }}
              src={Close_icon}
              onClick={() => setUserBrowser(false)}
            />
          </div>
          <strong>Improve your experience</strong>
          <p className="paragraph-one mt-2">
            <b>Connect2Clinic.com</b> can be best accessed from Chrome and
            Safari.
          </p>
          <div className="d-flex justify-content-center">
            <img className="browser-image" alt="" src={Chrome} />
            <img className="browser-image" alt="" src={Safari} />
          </div>
          <p className="paragraph-two">
            In case you have these browsers installed on your device, kindly try
            accessing the site using these browsers for better web experience.
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Homepagec2c;
