import React, { useEffect } from "react";
import { LOCALENV } from "./components/common/config";
import "./App.css";
import "./globalStyles/dashboardStyle.css";
import "./globalStyles/dashboardResponsive.css";
import "./globalStyles/doctorProfileStyle.css";
import "./globalStyles/patientDetails.css";
import "./globalStyles/login.css";
import "./globalStyles/video.css";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";
import "react-widgets/dist/css/react-widgets.css";
import "rsuite/dist/styles/rsuite-default.css";


import RequestTimedOut from "./components/RequestTimedOut";
import connect2clinic from "./components/connect2clinic"; //AppiontmentList popup
import HelpAndFaq from "./components/Faq/HelpAndFaq";   //DM popup
import Homepagec2c from "./components/HomepageNew/Homepagec2c"; //DM popup

import AdminSection from "./LazyLoading/AdminSection";
import { LastLocationProvider } from "react-router-last-location";
import Logout from "./components/common/Logout";

const reload = () => window.location.reload();
// triggering pipeline for faq issue
function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const fromApp = urlParams.get("fromApp");
  if (["prod"].includes(process.env.REACT_APP_ENV)) {
    // for google analytics - for production
    ReactGA.initialize("UA-173460690-1");
  } else {
    //for google analytics - development
    ReactGA.initialize("UA-173460690-2");
  }
  ReactGA.pageview(window.location.href);
  return (
    <>
      {fromApp == "true" ? null : (
        <SmartBanner
          title={"Connect2Clinic"}
          daysHidden={0}
          daysReminder={1}
          position={"Top"}
          price={{
            ios: "FREE",
            android: "FREE",
          }}
          appMeta={{
            ios: "apple-itunes-app",
            android: "google-play-app",
          }}
          button={"View"}
          ignoreIosVersion={false}
          style={{ minHeight: "10vh", backgroundColor: "blue" }}
        />
      )}
      <Router>
        <LastLocationProvider>
          <Switch>
           

            {/*! DO NOT CHANGE ADMIN ROUTES.. ALL ROUTES OF ADMINS ARE MANAGED BY BELOW COMPONENT!*/}
            <Route path="/admin/" component={AdminSection} />

            
            {/* <Route path="/dsm/" component={DSMSection} /> */}
            <Route path="/requesttimedout" component={RequestTimedOut} />
            <Route
              path="/.well-known/apple-app-site-association"
              onEnter={reload}
            />
            <Route path="/apple-app-site-association" onEnter={reload} />
            {/* <Route path="/receiver/video" component={ReceiverVideo} /> */}
            <Route path="/logout" component={Logout} />
            <Route exact path = "/">
              <Redirect to="/admin"/>
            </Route>
          </Switch>
        </LastLocationProvider>
      </Router>
    </>
  );
}
export default App;
