import React, { useEffect, useState } from "react";
import Menu from "material-ui-popup-state/HoverMenu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { Link } from "react-router-dom";
import "./styles/MyAccountAndLoginButton.scss";
import Arrowdown from "../../assets/forhomepage/arrowdown-newhomepage.svg";
import PersonHome from "../../assets/forhomepage/person-newHomepage.svg";

const MenuPopupState = (props) => {
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });

  const [userType, setUserType] = useState(null);
  console.log("isComingFromHeader", props.isComingFromHeader);
  let staff_clinic_association = localStorage.getItem(
    "staff_clinic_association"
  );
  let doctor_clinic_association = localStorage.getItem(
    "doctor_clinic_association"
  );
  useEffect(() => {
    if (!window.localStorage.getItem("user_type")) {
      setUserType(null);
    } else {
      if (
        staff_clinic_association === "true" ||
        doctor_clinic_association === "true"
      ) {
        setUserType("clinic");
      } else {
        if (window.localStorage.getItem("user_type") === "staff") {
          setUserType("doctor");
        } else {
          setUserType(window.localStorage.getItem("user_type"));
        }
      }
    }
  }, [window.localStorage]);

  if (
    userType !== "patient" &&
    userType !== "doctor" &&
    userType !== "staff" &&
    userType !== "clinic"
  ) {
    return (
      <React.Fragment>
        <div
          className={
            props.isComingFromHeader !== undefined && props.isComingFromHeader
              ? "menu-custom-button-Header wrapper-login"
              : "menu-custom-button wrapper-login"
          }
          {...bindHover(popupState)}
        >
          {props.isComingFromHeader !== undefined &&
          props.isComingFromHeader ? (
            <img src={require("../../assets/person-24px.png")} alt="#" />
          ) : (
            <img src={PersonHome} alt="#" />
          )}
          <div className="right-menu">Login</div>
          {props.isComingFromHeader !== undefined &&
          props.isComingFromHeader ? (
            <img src={require("../../assets/arrow-down.png")} alt="#" />
          ) : (
            <img className="arrowdown-mob" src={Arrowdown} alt="#" />
          )}
        </div>

        <Menu
          {...bindMenu(popupState)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <MenuItem onClick={popupState.close}>
            <Link className="thecolor" to={`/doctor/login`}>
              Doctor Login
            </Link>
          </MenuItem>
          <MenuItem onClick={popupState.close}>
            <Link
              className="thecolor"
              onClick={() => localStorage.setItem("nav_item_doctor", 2)}
              to={`/patient/login`}
            >
              Patient Login
            </Link>
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }

  const logoutHandler = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  return (
    <React.Fragment>
      <div
        className={
          props.isComingFromHeader !== undefined && props.isComingFromHeader
            ? "menu-custom-button-Header wrapper-login"
            : "menu-custom-button wrapper-login"
        }
        {...bindHover(popupState)}
      >
        {props.isComingFromHeader !== undefined && props.isComingFromHeader ? (
          <img src={require("../../assets/person-24px.png")} alt="#" />
        ) : (
          <img src={PersonHome} alt="#" />
        )}

        <div className="right-menu">My Account</div>

        {props.isComingFromHeader !== undefined && props.isComingFromHeader ? (
          <img src={require("../../assets/arrow-down.png")} alt="#" />
        ) : (
          <img className="arrowdown-mob" src={Arrowdown} alt="#" />
        )}
      </div>

      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem onClick={popupState.close}>
          <Link className="thecolor" to={`/${userType}/account/dashboard`}>
            Dashboard
          </Link>
        </MenuItem>
        {userType === "patient" && (
          <MenuItem onClick={popupState.close}>
            <Link
              className="thecolor"
              onClick={() => localStorage.setItem("nav_item_doctor", 2)}
              to={{
                pathname: `/${userType}/account/appointmenthistory`,
                state: { previousCheckup: 2 },
              }}
            >
              Appointment History
            </Link>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            popupState.close();
            logoutHandler();
          }}
        >
          <Link
            className="thecolor"
            to={`/${userType === "clinic" ? "doctor" : userType}/login`}
          >
            Logout
          </Link>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default MenuPopupState;
