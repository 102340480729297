import { instanceOf } from "prop-types";
import React, { Component } from "react";
import { Cookies } from "react-cookie";
import { withRouter } from "react-router";
import swal from "sweetalert";
import Access from "../assets/doctor-login/access.png";
import AppleStore from "../assets/doctor-login/apple-store.png";
import Consult from "../assets/doctor-login/consult.png";
import QR from "../assets/doctor-login/doctor-qr.png";
import GooglePlay from "../assets/doctor-login/google-play.png";
import AccessMob from "../assets/doctor-login/mobile/access.png";
import AppleStoreMob from "../assets/doctor-login/mobile/apple-store.png";
import ConsultMob from "../assets/doctor-login/mobile/consult.png";
import QRMob from "../assets/doctor-login/mobile/doctor-qr.png";
import GooglePlayMob from "../assets/doctor-login/mobile/google-play.png";
//Images for mobile-view
import PresenceMob from "../assets/doctor-login/mobile/presence.png";
import SecureMob from "../assets/doctor-login/mobile/secure.png";
//Images for web-view
import Presence from "../assets/doctor-login/presence.png";
import Secure from "../assets/doctor-login/secure.png";
import "../globalStyles/doctorLogin.css";
import "../globalStyles/login.css";
import "../globalStyles/onBoardedDoctorLogin.scss";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import authAxios from "../Util/API Calls/authAxios";
// import DoctorLogin from "./DoctorLogin/index";
// import Footer from "./Footer";
import Header from "./Header";
import UserBrowser from "./UserBrowser";

const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });
  return valid;
};
const onlyDigitRegex = RegExp(/^[0-9]+$/);

class connect2clinic extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props, context) {
    super(props, context);
    const { cookies } = props;
    var type = this.props.match.params.doctorType;
    this.inputRef = React.createRef();
    this.state = {
      onboardAppId: "",
      showOnboarding: false,
      isPartialDoctorTest: false,
      success: "failureotp",
      stubbornButton: false,
      disappearTimer: 5,
      showDisappearTimer: false,
      otpMessage: "",
      errorOtpMessage: "",
      send: "Send",
      docType: type,
      phone_number: "",
      otp: "",
      formErrors: {
        phone_number: "",
        otp: "",
      },
      validation_msg: "",
      showOtpTimer: false,
      otpTimer: {
        minutes: 2,
        seconds: 0,
      },
      loading: false,
    };
    this.handleChange.bind(this);
    this.onSubmit.bind(this);
    this.onOtpSend.bind(this);
    this.handleOtp.bind(this);
    this.onInputChange.bind(this);
  }

  showMessageTimer = () => {
    this.setState({ showDisappearTimer: true });
    this.messageTimer = setInterval(() => {
      if (this.state.disappearTimer > 0) {
        this.setState({
          disappearTimer: this.state.disappearTimer - 1,
        });
      }
      if (this.state.disappearTimer == 0) {
        clearInterval(this.messageTimer);
        this.setState({ showDisappearTimer: false });
      }
    }, 1000);
  };

  startTimer = () => {
    this.setState({ showOtpTimer: true });
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state.otpTimer;
      if (seconds > 0) {
        this.setState(({ otpTimer }) => ({
          otpTimer: {
            ...otpTimer,
            seconds: otpTimer.seconds - 1,
          },
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
          this.setState({
            showOtpTimer: false,
          });
        } else {
          this.setState(({ otpTimer }) => ({
            otpTimer: {
              minutes: otpTimer.minutes - 1,
              seconds: 59,
            },
          }));
        }
      }
    }, 1000);
  };

  handleChange = (name) => (event) => {
    const target = event.target;
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (name == "otp") {
      formErrors.otp = "";
      this.setState(
        {
          otp: event.target.value,
          formErrors: formErrors,
        },
        () => {}
      );
    } else {
      if (value.length > 0) {
        formErrors.phone_number =
          onlyDigitRegex.test(value) && value.length == 10
            ? ""
            : "Please enter valid Phone Number";
        this.setState(
          {
            phone_number: event.target.value,
            formErrors: formErrors,
          },
          () => {}
        );
        //this.setState({formErrors});
      } else {
        formErrors.phone_number =
          value.length > 0 ? "" : " Phone Number is Mandatory";
        this.setState(
          {
            phone_number: event.target.value,
            formErrors: formErrors,
          },
          () => {}
        );
      }
    }
  };

  //handleOtp(event) {
  //const target = event.target;
  //const { name, value } = event.target;
  //let formErrors = this.state.formErrors;
  //formErrors.otp = value.length > 0 ? "" : " Provide Data For OTP";
  //this.setState(
  //{
  //otp: event.target.value,
  //formErrors: formErrors,
  //},
  //() => {}
  //);
  //}

  handleOtp = (e) => this.setState({ otp: e.target.value });

  componentWillMount() {
    let doctorPhone = localStorage.getItem("doctorPhone");
    if (doctorPhone) {
      localStorage.removeItem("doctorPhone");
    }
    let id_token = localStorage.getItem("id_token");

    if (id_token) {
      if (localStorage.getItem("stay-login") === true) {
        this.props.history.push("/doctor/account/dashboard");
      }
    }

    let onBoardedDoctor = localStorage.getItem("onBoardedDoctor");
    if (onBoardedDoctor) {
      localStorage.removeItem("onBoardedDoctor");
      //this.props.history.push("/doctoraccount");
    }

    // localStorage.removeItem("id_token");
    // localStorage.removeItem("user_type");
    // localStorage.removeItem("user_id");
    // localStorage.removeItem("user_name");
    // localStorage.removeItem("full_name");
  }

  onInputChange(event) {
    if (event.target.value.length == 6) {
      this.inputRef.current.blur();
      this.setState({ success: "" });
    } else {
      this.setState({ success: "failureotp" });
    }
  }

  onSubmit(event) {
    localStorage.removeItem("id_token");
    if (
      this.state.phone_number != "" &&
      this.state.otp.length == 6 &&
      this.state.otp != "" &&
      formValid(this.state.formErrors)
    ) {
      var phone_no = "+91" + this.state.phone_number;
      var data = {
        phone_no: phone_no,
        user_otp: this.state.otp,
        user_type: "doctor",
      };
      this.setState(
        {
          validation_msg: "",
        },
        () => {}
      );
      this.setState({ loading: true });

      authAxios
        .post("login", {
          phone_no: phone_no,
          user_otp: this.state.otp,
          user_type: "doctor",
        })
        .then((response) => {
          this.setState({ loading: false });

          if (response) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                localStorage.setItem("id_token", response.data.user.id_token);
                localStorage.setItem("user_id", response.data.user.user_id);
                localStorage.setItem("user_type", response.data.user.user_type);
                localStorage.setItem("user_name", response.data.user.user_name);
                localStorage.setItem("full_name", response.data.user.full_name);
                localStorage.setItem("doctorPhone", phone_no);
                localStorage.setItem(
                  "last_login_date",
                  response.data.user.last_login_date
                );

                if (this.state.isPartialDoctorTest == true) {
                  localStorage.setItem("onboardingDoctor", true);
                }

                if (!response.data.user.last_login_date) {
                  localStorage.setItem("last_login_date", "");
                  localStorage.setItem("Home", true);
                  localStorage.setItem("Appointment History", true);
                  localStorage.setItem("Messages", true);
                  localStorage.setItem("Accounts", true);
                  localStorage.setItem("My Schedule & Fees", true);
                }
                this.setState({}, () => {});
                // localStorage.setItem("onBoardedDoctor", false);
                var message = response.data.message;

                if (localStorage.getItem("id_token")) {
                  let lastPathBeforeLogin =
                    this.props.cookies["lastPathBeforeLogin"];
                  if (lastPathBeforeLogin) {
                    this.props.history.push(lastPathBeforeLogin);
                  }
                  this.props.history.push("/doctor/account/dashboard");
                }
              } else {
                afterLoginApiAxios({
                  method: "post",
                  url: "doctor/webinar-login",
                  data: {
                    "authorizer-principal-id": {
                      user_type: "1",
                    },
                    phone_no: phone_no,
                    user_otp: this.state.otp,
                    onboard_app_id: this.state.onboardAppId,
                  },
                }).then((res) => {
                  // console.log(res.data);
                  if (res.data.statusCode == 200) {
                    localStorage.setItem("id_token", res.data.user.id_token);
                    localStorage.setItem("user_id", res.data.user.user_id);
                    localStorage.setItem("user_type", res.data.user.user_type);
                    localStorage.setItem("doctorPhone", phone_no);
                    localStorage.setItem(
                      "specialization",
                      response?.data?.user?.specialization_id
                    );

                    localStorage.setItem("onBoardedDoctor", true);
                    if (localStorage.getItem("id_token")) {
                      this.props.history.push(
                        "/doctor/account/dashboard?utm_source=webapp&utm_medium=onboarding"
                      );
                    }
                  } else {
                    swal({
                      title: "Invalid OTP",
                      type: "error",
                      icon: "warning",
                    });
                  }
                });

                // var message = response.data.message;
                // swal({
                //   title: "Login Failed!",
                //   text: message,
                //   type: "error",
                //   icon: "warning",
                // }).then(function () {});
              }
            } else {
              var message = "Something Went Wrong!!Try Again";
              swal({
                title: "Login Failed!",
                text: message,
                type: "error",
                icon: "warning",
              }).then(function () {});
            }
          } else {
            var message = "Something Went Wrong!!Try Again";
            swal({
              title: "Login Failed!",
              text: message,
              type: "error",
              icon: "warning",
            }).then(function () {});
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } else {
      let formErrors = this.state.formErrors;
      formErrors = {
        phone_number: "",
        otp: "",
      };
      if (!this.state.phone_number) {
        formErrors.phone_number = "Phone Number is Mandatory";
        this.setState({ formErrors });
      } else if (
        this.state.phone_number.length > 0 &&
        this.state.phone_number.length < 10
      ) {
        formErrors.phone_number = "Provide Valid Phone Number";
        this.setState({ formErrors });
      } else if (this.state.otp.length != 6) {
        formErrors.otp = "OTP must be 6 digits";
        this.setState({ formErrors });
      } else {
        formErrors.otp = "Provide Valid Otp";
        this.setState({ formErrors });
      }
    }
  }
  onOtpSend() {
    if (this.inputRef.current) this.inputRef.current.value = "";
    let formErrors = this.state.formErrors;
    if (
      !this.state.phone_number ||
      this.state.phone_number.length < 10 ||
      this.state.phone_number.length > 10
    ) {
      formErrors.phone_number = "Please Provide Valid Phone Number";
      this.setState({ formErrors });
    } else if (Number(this.state.phone_number) < 1000000000) {
      formErrors.phone_number = "Please Provide Valid Phone Number";
      this.setState({ formErrors });
    } else {
      var phonenumber = this.state.phone_number;
      var phone_number = "+91" + phonenumber; //+918017029639

      formErrors.otp = "";

      //Start Timer
      this.setState({
        disappearTimer: 5,
        otpTimer: {
          minutes: 2,
          seconds: 0,
        },
        stubbornButton: true,
        formErrors: formErrors,
      });
      clearInterval(this.myInterval);

      this.setState({ loading: true });
      afterLoginApiAxios
        .post("public/send-otp", {
          phone_no: phone_number,
          channel_id: "doctor-login",
        })
        .then((response) => {
          this.setState({ loading: false });

          if (response) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                let msg = response.data.message.split(" ");
                this.setState(
                  {
                    send: "Resend",
                    otpMessage: msg[msg.length - 1],
                    errorOtpMessage: "",
                  },
                  () => {}
                );

                this.startTimer();
                this.showMessageTimer();
              } else {
                // here goes surabhi's new otp api

                afterLoginApiAxios
                  .post("public/send-onboard-doctor-OTP", {
                    phone_no: phone_number,
                    channel_id: "doctor-login",
                  })
                  .then((res) => {
                    this.setState({ loading: false });
                    if (res.data.statusCode == 200) {
                      console.log(res.data, "res.data");
                      this.startTimer();
                      this.showMessageTimer();
                      let msg1 = res.data.message.split(" ");
                      let appId = res.data.onboard_application_id;
                      this.setState(
                        {
                          send: "Resend",
                          onboardAppId: appId,
                          otpMessage: msg1[msg1.length - 1],
                          errorOtpMessage: "",
                          isPartialDoctorTest: true,
                        },
                        () => {}
                      );
                    } else if (res.data.statusCode == 503) {
                      this.setState(
                        {
                          loading: false,
                          showOnboarding: true,
                          otpMessage: "",
                          stubbornButton: false,
                        },
                        () => {}
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                // here goes surabhi's new otp api

                this.setState({}, () => {});
              }
            } else {
              var message = "Something Went Wrong!!Try Again";
              swal({
                title: "Failed!",
                text: message,
                type: "error",
                icon: "warning",
              }).then(function () {});
            }
          } else {
            var message = "Something Went Wrong!!Try Again";
            swal({
              title: "Failed!",
              text: message,
              type: "error",
              icon: "warning",
            }).then(function () {});
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    }
  }

  HideModal = () => {
    this.setState({ showOnboarding: false });
  };

  resetMobileNumber = () => {
    this.setState({ errorOtpMessage: "" });
  };

  render() {
    var styles = {
      cardOdd: {
        backgroundColor: "#eeae01",
        color: "#454545",
      },
      cardEven: {
        backgroundColor: "#454545",
        color: "#eeae01",
      },
    };
    return (
      <div>
        <Header />
        <div className="container-fluid ">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-8 mx-0 px-0">
              <div className="masked-doctor px-lg-5 custom-item">
                <div className="container custom-container">
                  <div className="row">
                    <h1 id="heading">For Doctors</h1>
                  </div>
                  <div className="row">
                    <p className="details">
                      Launch your online practice today and connect with your
                      patients anytime, anywhere. Getting started on
                      Connect2Clinic takes just a few minutes.
                    </p>
                  </div>
                  <div className="row my-4">
                    <div className="col-lg-4">
                      <div className="row align-items-center grid-system">
                        <picture>
                          <source
                            media="(max-width: 567px)"
                            srcSet={PresenceMob}
                          />
                          <img src={Presence} alt="presence icon" />
                        </picture>
                        <span className="ml-2 grid-details">
                          Enhance your global presence
                        </span>
                      </div>
                      <div id="grid-system" className="row align-items-center">
                        <picture>
                          <source
                            media="(max-width: 567px)"
                            srcSet={ConsultMob}
                          />
                          <img src={Consult} alt="consult icon" />
                        </picture>
                        <span className="ml-2 grid-details">
                          Consult from anywhere
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row align-items-center grid-system">
                        <picture>
                          <source
                            media="(max-width: 567px)"
                            srcSet={SecureMob}
                          />
                          <img src={Secure} alt="secure icon" />
                        </picture>
                        <span className="ml-2 grid-details">
                          Secure your Patients with unique link
                        </span>
                      </div>
                      <div className="row align-items-center">
                        <picture>
                          <source
                            media="(max-width: 567px)"
                            srcSet={AccessMob}
                          />
                          <img src={Access} alt="access icon" />
                        </picture>
                        <span className="ml-2 grid-details">
                          Quick access to exclusive content
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="sub-heading">Available On</h5>
                  </div>
                  <div className="row align-items-center align-content-between justify-content-center justify-content-md-start mb-5 mb-md-0">
                    <div className="col-lg-2 col-md-2 col-offset-2  md-offset-0 col-4 p-0">
                      <picture>
                        <source media="(max-width: 567px)" srcSet={QR} />
                        <img src={QRMob} alt="qr" />
                      </picture>
                    </div>
                    <div className="col-lg-6 col-md-6 col-5">
                      <div className="row">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.connect2clinic.app"
                          target="_blank"
                        >
                          <picture>
                            <source
                              media="(max-width: 567px)"
                              srcSet={GooglePlayMob}
                            />
                            <img src={GooglePlay} alt="google-play" />
                          </picture>
                        </a>
                      </div>
                      <div className="row mt-2">
                        <a
                          href="https://apps.apple.com/in/app/connect2clinic/id1530572274"
                          target="_blank"
                        >
                          <picture>
                            <source
                              media="(max-width: 567px)"
                              srcSet={AppleStoreMob}
                            />
                            <img src={AppleStore} alt="apple-store" />
                          </picture>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="px-0 mx-0 col-sm-12 col-lg-4 col-md-6 text-center d-flex flex-column align-items-center justify-content-center">
              <DoctorLogin showPatientLoginModal={false} />
            </div> */}
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
export default UserBrowser(withRouter(connect2clinic));
