import axios from "axios";
import { LOCALENV } from "../../components/common/config/index";

let apiMapUrl = {
  dev: "https://api-dev.c2cassets.com/",
  dev1: "https://api-dev1.c2cassets.com/auth/",
  dev2: "https://api-dev2.c2cassets.com/auth/",
  uat: "https://api-uat.c2cassets.com/auth/",
  prod: "https://api.connect2clinic.com/auth/",
  qa: "https://api-qa.c2cassets.com/auth/",
  maint: "https://api-maint.c2cassets.com/",
  stage: "https://api-stage.c2cassets.com/",
};

const api =
  window.location.hostname === "localhost"
    ? LOCALENV
    : process.env.REACT_APP_ENV;
const authAxios = axios.create({
  baseURL: apiMapUrl[api],
});
authAxios.defaults.headers.common["X-Application-Source"] = "web";
export default authAxios;
