import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";

class RequestTimedOut extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      msg: localStorage.getItem("fail_msg"),
    };
  }

  render() {
    return (
      <div>
        <div>
          <header>
            <div className="container-fluid">
              <div className="row mt-4">
                <div className="col-6 col-md-3">
                  <a href="" className="logo">
                    <img
                      src={process.env.PUBLIC_URL + "/images/HN_logo.png"}
                      alt="logo"
                    />
                  </a>
                </div>
                <div className="col-6 col-md-9 text-right">
                  <Link className="mr-5 otp-btn">Logout</Link>
                </div>
              </div>
            </div>
          </header>
          <section className="pro-body-content">
            <div className="pro-content2">
              <div className="container-fluid">
                <div className="pro-content-col">
                  <div className="row">
                    <div className="col-md-8 offset-md-2 text-center doctor-profile">
                      <h2>Booking Request Failed</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 offset-md-2 text-center doctor-profile pt-4">
                      <p style={{ color: "#404760" }}>{this.state.msg}</p>
                      {/* <p style={{ color: "#404760" }}>
                        For any other query contact customer happiness team at{" "}
                        <b style={{ color: "#17bae0" }}>(033) 405 85600</b>
                      </p> */}
                    </div>

                    <div className="col-md-4 offset-md-4 doctor-profile mb-5">
                      <Link
                        to="/patient/account/dashboard"
                        href="#"
                        className="otp-btn w-75"
                      >
                        Back to Home
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default RequestTimedOut;
