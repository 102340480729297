
import { Logger as CloudWatchLogger } from 'aws-cloudwatch-log-browser';

function getUserId() {
  if (localStorage.getItem('user_type') === 'staff') {
    return localStorage.getItem('staff_id');
  } else {
    return localStorage.getItem('user_id');
  }
}


class Logger {
//   constructor(cloudWatchClient) {}

  // initializing cloudwatch client
  initializeCloudWatchClient() {
    if (this.cloudWatchClient === undefined) {
      const config = {
        logGroupName: 'frontend-dev-logs',
        logStreamName: 'frontend-logs',
        region: 'ap-south-1',
        accessKeyId: 'AKIA3JUEYN6P5BIWJ5FT',
        secretAccessKey: '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',
        uploadFreq: 100,
        local: false,
      };
      this.cloudWatchClient = new CloudWatchLogger(config);
    } else {
      throw new Error('CloudWatch has already been initialized.');
    }
  }

  isCloudWatchClientInitialized() {
    if (!this.cloudWatchClient) {
      return false;
    }
    return true;
  }

  error(message) {
    if (!this.isCloudWatchClientInitialized()) {
      // throw Error
      throw new Error('CloudWatch Client is not initialized.');
    }
    // this.cloudWatchClient.log()
    this.onError({message: message}, { type: 'ERROR' });
  }

  warn(message) {
    if (!this.isCloudWatchClientInitialized()) {
      // throw Error
      throw new Error('CloudWatch Client is not initialized.');
    }
    this.onError({message: message}, { type: 'WARNING' });
  }

  info(message) {
    if (!this.isCloudWatchClientInitialized()) {
      // throw Error
      throw new Error('CloudWatch Client is not initialized.');
    }

    this.onError({message: message}, { type: 'INFO' });
  }

  onError(e, info = {}) {

    if (!this.isCloudWatchClientInitialized()) {
      // throw Error
      throw new Error('CloudWatch Client is not initialized.');
    }
    const messageType = info.type ? info.type : 'ERROR';
    // write cloudwatch logging part here.

    this.cloudWatchClient.log({
      source: 'WebUI',
      message: {
        error: e.message,
        // exception: e.stack,
        currentLocation: window.location.href,
      },
      message_type: messageType,
      user_agent: window.navigator.userAgent,
      user_type: 1,
      user_id: getUserId(),
      function_name: null,
    });
  }

 
  
}

const logger = new Logger();

export default logger;
