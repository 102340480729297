import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Maq from "./MostAskedQuestion.js";
import Categories from "./Categories";
import MyAccountButton from "./MyAccountButton";
import SearchedQuery from "./SearchedQuery";
import cross from "../../assets/cross.svg";
import "../styles/Faq.scss";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import swal from "sweetalert";
import { open, closed } from "../styles/UtilitiesFaq/ColorChangeObject";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Drawer from "./Drawer";
import Collapse from "@material-ui/core/Collapse";
import { connect } from "react-redux";
import GlobalFooter from "../GlobalFooter";
var detectedCategory = [];
var maq_data = [];

const popularSearch = ` Account Setup, Appointment Cancellation`;
export class HelpAndFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      answer: "",
      testData: "",
      maqswitch: false,
      categorieswitch: false,
      raiseticketswitch: false,
      yes: false,
      no: false,
      noo: false,
      linkstyle: true,
      linkstyle1: false,
      linkstyle2: false,
      user_type_current: "",
      finalCheck: false,
      drawerClose: false,
    };
    this.run = this.run.bind(this);
  }

  componentDidMount() {
    this.getGlobalTemplates(this.props);

    let that = this;
    afterLoginApiAxios
      .post(
        "public/get-faq-list",
        {
          user_type: 2,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          var responseData = response.data;
          this.setState({
            uploadingFile: false,
          });
          this.setState(
            {
              data: response.data.result,
            },
            () => {
              let user;
              if (localStorage.getItem("user_type") === null) {
                user = "patient";
              } else {
                user =
                  localStorage.getItem("user_type") === "staff"
                    ? "doctor"
                    : localStorage.getItem("user_type");
              }
              this.setState({
                user_type_is: localStorage.getItem("user_type"),
              });
              for (var i = 0, l = this.state.data.length; i < l; i++) {
                if (
                  this.state.data[i].section.toLowerCase() ===
                  user.toLowerCase()
                ) {
                  detectedCategory.push(this.state.data[i]);
                }
              }

              this.setState({ searchData: detectedCategory });

              for (let i = 0, l = this.state.data.length; i < l; i++) {
                if (
                  this.state.data[i].maq === "Yes" &&
                  this.state.data[i].section.toLowerCase() ===
                    user.toLowerCase()
                ) {
                  maq_data.push(this.state.data[i]);
                }
              }
              this.setState({ maq: maq_data });
            }
          );

          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
              } else {
                swal({
                  title: "Deleted Successfully",
                  text: "Delete Document Successfully",
                  type: "success",
                });
              }
            } else {
              this.setState(
                {
                  message: "Upload Failed",
                  response_message: "Something Went Wrong!Please Try Again!!",
                  dataload: false,
                  uploadingFile: false,
                },
                () => {}
              );
            }
          }
        }
      })
      .catch(function () {
        alert("Please upload only jpeg,jpg,png,pdf or doc file");
        that.setState({
          message: "Upload Failed",
          uploadingFile: false,
        });
      });
    if (localStorage.getItem("user_type") === null) {
      this.setState({ user_type_current: "patient" });
    } else {
      this.setState({ user_type_current: localStorage.getItem("user_type") });
    }
  }

  getGlobalTemplates = (props) => {
    afterLoginApiAxios
      .get("/public/get-customer-support-details")
      .then(function (response) {
        props.dispatch({
          type: "UPDATE_MOBILE_NUMBER",
          payload: {
            mobileNumber: response.data.customer_support_no,
            emailId: response.data.customer_support_emailid,
          },
        });
        localStorage.setItem("mobileNumber", response.data.customer_support_no);
        localStorage.setItem("emailId", response.data.customer_support_emailid);
      })
      .catch(function (error) {
        console.error(error);
        props.dispatch({
          type: "UPDATE_MOBILE_NUMBER",
          payload: {
            mobileNumber: "(033) 405 85600",
            emailId: "hello@connect2clinic.com",
          },
        });
      })
      .then(function (result) {});
  };

  run = (quest, ans) => {
    let collappse = quest;

    if (collappse.substring(0, 2) !== "No") {
      if (collappse.length > 0) {
        this.setState({ open: true });
        this.setState({ question: quest });
        this.setState({ answer: ans });
        this.setState({ yes: false });
        this.setState({ no: false, finalCheck: false });
      }
    }
  };

  userType = (current_user) => {
    if (current_user.toLowerCase() === "patient") {
      let user = "doctor";

      let local_user = user;
      let local_detectedCategory = [];
      let local_maq = [];
      for (var i = 0, l = this.state.data.length; i < l; i++) {
        if (
          this.state.data[i].section.toLowerCase() === local_user.toLowerCase()
        ) {
          local_detectedCategory.push(this.state.data[i]);
        }
      }

      this.setState({ searchData: local_detectedCategory });

      for (let i = 0, l = this.state.data.length; i < l; i++) {
        if (
          this.state.data[i].maq === "Yes" &&
          this.state.data[i].section.toLowerCase() === local_user.toLowerCase()
        ) {
          local_maq.push(this.state.data[i]);
        }
      }
      this.setState({ maq: local_maq });
      this.setState({ user_type_current: "doctor" });
    } else if (
      current_user.toLowerCase() === "doctor" ||
      current_user.toLowerCase() === "staff"
    ) {
      let user = "patient";

      let local_user = user;
      let local_detectedCategory = [];
      let local_maq = [];
      for (let i = 0, l = this.state.data.length; i < l; i++) {
        if (
          this.state.data[i].section.toLowerCase() === local_user.toLowerCase()
        ) {
          local_detectedCategory.push(this.state.data[i]);
        }
      }

      this.setState({ searchData: local_detectedCategory });

      for (let i = 0, l = this.state.data.length; i < l; i++) {
        if (
          this.state.data[i].maq === "Yes" &&
          this.state.data[i].section.toLowerCase() === local_user.toLowerCase()
        ) {
          local_maq.push(this.state.data[i]);
        }
      }
      this.setState({ maq: local_maq });
      this.setState({ user_type_current: "patient" });
    }
  };

  handleDrawerClose = () => {
    this.setState();
  };

  render() {
    return (
      <div className="header-color">
        <div className=" row-xs-3">
          <Card
            style={{ border: "0px", width: "100%" }}
            className="  col-xs-12"
          >
            <div className="App">
              <div className="c2clogo">
                <img src={require("../../assets/c2c_logo.png")} alt="#" />
              </div>

              <div className="header-content-container">
                <div className="header-content">
                  <div className="align-header">
                    <img src={require("../../assets/call-24px.png")} alt="#" />
                    <div>
                      {" "}
                      <a href={"tel:" + this.props.mobileNumber} className="a">
                        {this.props.mobileNumber}
                      </a>
                    </div>
                  </div>
                  <div className="align-header2">
                    <img src={require("../../assets/mail-icon.png")} alt="#" />
                    <a href={"mailto:" + this.props.emailId} className="a">
                      {this.props.emailId}
                    </a>
                  </div>
                  <div className="wrapper">
                    <img
                      src={require("../../assets/person-24px.png")}
                      alt="#"
                    />
                    <div className="right-menu">
                      <MyAccountButton />
                    </div>
                    <div>
                      <img
                        src={require("../../assets/arrow-down.png")}
                        alt="#"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="drawer-nav">
                <Drawer />
              </div>
            </div>

            <div className="bg-image">
              <div className="selected-user">
                <div className="both-user-width">
                  <Row>
                    <div className="user-entry">
                      <div
                        onClick={() => {
                          this.userType(this.state.user_type_current);
                        }}
                        className={
                          this.state.user_type_current.toLowerCase() ===
                          "doctor"
                            ? "user-patient"
                            : "user-doctor"
                        }
                      >
                        {`I'm a Doctor`}
                      </div>
                      <div
                        onClick={() => {
                          this.userType(this.state.user_type_current);
                        }}
                        className={
                          this.state.user_type_current.toLowerCase() ===
                          "doctor"
                            ? "user-doctor"
                            : "user-patient"
                        }
                      >
                        {`I'm a Patient`}
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <Col className="layout-area">
                <div className="layout-help">Hello, How can we help you?</div>
              </Col>

              <div className="search-area">
                <div className="search-box-space">
                  <div className="search-box">
                    <SearchedQuery
                      run={this.run}
                      temporaryData={this.state.searchData}
                    />
                    <img
                      src={require("../../assets/search.png")}
                      alt=""
                      width="34"
                      height="30"
                    />
                  </div>
                </div>
              </div>

              <Row className="layout-popular-searches">
                <div className="in-layout1">{`Popular Searches : `}</div>
                <div className="in-layout2 "> {popularSearch}</div>
              </Row>
            </div>

            <div className="xyz">
              <Collapse in={this.state.open} className="the-searched-question">
                <Col className="design-for-question">
                  <div className="the-alignment-question">
                    <Row className="align-question">
                      <div className="question-style col-10">
                        {this.state.question}
                      </div>
                      <div
                        className="cross col-2"
                        onClick={() => {
                          this.setState({ open: false });
                        }}
                      >
                        <div
                          className="close-collapse m-1"
                          onClick={() => {
                            this.setState({ open: false });
                          }}
                        >
                          Close
                        </div>
                        <img src={cross} alt="#" height="6px" width="14px" />
                      </div>
                    </Row>
                    <div className="answer-style">{this.state.answer}</div>
                    <div className="bottom-default-question">
                      Was this helpful ?
                    </div>
                    <Col className="faq-button-pad" style={{ padding: "0px" }}>
                      {this.state.yes ? (
                        <Col>ThankYou for your Feedback</Col>
                      ) : (
                        <div className="align-click-button">
                          <div
                            className={
                              this.state.yes
                                ? "yes-no-button-toggle"
                                : this.state.finalCheck
                                ? "yes-no-button-toggle"
                                : "yes-no-button"
                            }
                            onClick={() => {
                              this.setState({ yes: true }, () => {
                                this.setState({ no: false });
                              });
                            }}
                          >
                            Yes
                          </div>
                          <a
                            style={this.state.no ? closed : open}
                            href="#raiseticket"
                          >
                            <div
                              className={
                                this.state.no
                                  ? "yes-no-button"
                                  : "yes-no-button-toggle"
                              }
                              onClick={() => {
                                // window.openWidget();
                              }}
                              type="button"
                            >
                              No
                            </div>
                          </a>
                        </div>
                      )}
                    </Col>
                  </div>
                </Col>
              </Collapse>
            </div>
            <div className="bottom-nav">
              <div className="bottom-nav-tag-name">
                <Col className="align-nav-button">
                  <Card.Link
                    href="#maq"
                    className={
                      this.state.linkstyle ? "bottom-text1" : "bottom-text"
                    }
                    onClick={() => {
                      this.setState(
                        { linkstyle: !this.state.linkstyle },
                        () => {
                          this.setState({
                            linkstyle1: false,
                            linkstyle2: false,
                          });
                        }
                      );
                    }}
                  >
                    Most Asked Questions
                  </Card.Link>
                  <Card.Link
                    href="#categories"
                    className={
                      this.state.linkstyle1 ? "bottom-text1" : "bottom-text"
                    }
                    onClick={() => {
                      this.setState(
                        { linkstyle1: !this.state.linkstyle1 },
                        () => {
                          this.setState({
                            linkstyle: false,
                            linkstyle2: false,
                          });
                        }
                      );
                    }}
                  >
                    Categories
                  </Card.Link>
                </Col>
              </div>
            </div>
          </Card>
        </div>
        <div id="maq" className="row-xs-3">
          <Maq maq={this.state.maq} />
        </div>
        <div id="categories" className="row-xs-3">
          <Categories temporaryData={this.state.searchData} />
        </div>
        <div className="row m-0">
          <div className="col-12 p-0">
            <GlobalFooter />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileNumber: state.global.mobileNumber,
  emailId: state.global.emailId,
});
export default connect(mapStateToProps)(HelpAndFaq);
